import HonorCard from "./HonorCard.js";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import hr from "../assets/curve-hr.svg";

export default function Honors() {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div id="honors" className="mt-4 text-white">
      <h1 className="text-2xl font-bold">Recommendations</h1>
      <p className="font-light text-gray-400">Here are some of my recommendations received at Linkedin</p>

      <div className="mt-4">
        <Slider {...settings}>
          <HonorCard name="Vignesh V" issued="Mr. Developer | AGI Researcher | Data Explorer | Machine Learning | Deep Learning | Creative thinker I Technology explorer |" desc="I had the pleasure of studying alongside Lokesh Balaji, who recently completed an internship at Spinacle as a Cyber Security Engineer. Lokesh has always impressed me with his exceptional skills in networking and technology. While his strengths lie more in practical application than academics, Lokesh's ability to grasp and apply complex technical concepts is outstanding. During our time together, he consistently demonstrated a deep understanding of cybersecurity and networking, often helping peers with challenging problems. As an intern, Lokesh's hands-on approach and proactive mindset were invaluable. He played a key role in identifying and addressing security vulnerabilities, significantly contributing to our team's success. Lokesh's passion for technology, combined with his collaborative spirit, makes him an excellent team player and a reliable resource. I highly recommend Lokesh for any role that leverages his strong technical skills. He is a valuable asset to any team." />
          <HonorCard name="V.Mahendar" issued="Datascience intern @iLinkdigital ||Student at Rajalakshmi Institute of technology||Innovator || AI aspirant & IILTTS #techgium Finalist" desc="I highly recommend Lokesh as a highly skilled software engineer. He excels in Java, Python, Kubernetes, and other key technologies. Lokesh consistently demonstrates exceptional problem-solving abilities, a deep understanding of programming principles, and a commitment to delivering high-quality code. His expertise and dedication make him a valuable asset to any team." />
          <HonorCard name="ADITH NARAYAN" issued="Student at Rajalakshmi Institute of Technology" desc="I am pleased to recommend Lokesh Balaji, a talented individual known for his exceptional communication skills, proficiency in DevOps, and remarkable contributions to the tech community. Lokesh is highly active on GitHub, consistently posting insightful content and engaging in meaningful projects. His dedication to sharing knowledge is evident through the frequent tech events he organizes and his guidance to juniors in the field. Lokesh's enthusiasm for his studies and passion for coding is truly inspiring. He approaches his work with high effectiveness and efficiency, consistently delivering excellent results. His ability to communicate complex ideas clearly and his proactive attitude make him an invaluable asset to any team. Lokesh's commitment to continuous learning and his genuine love for technology set him apart." />
          <HonorCard name="baranika sundharamoorthi" issued="student at Rajalakshmi Institute of technology" desc="I am writing to enthusiastically recommend Lokesh Balaji for any role he seeks to pursue in your esteemed organization. Lokesh is an exceptionally driven and talented individual, and I have had the pleasure of working closely with him as a college mate. His enthusiasm for technology and his proactive approach to learning and sharing knowledge make him stand out as a remarkable software and DevOps engineer. Lokesh's curiosity and collaborative spirit are among his greatest strengths. He is passionate about harnessing Artificial Intelligence and Data Science to drive meaningful impact, and his interest in coding is evident through the numerous projects he has undertaken out of his own initiative. His innovative ideas and strong work ethic enable him to excel in any organization, consistently bringing fresh perspectives and solutions to the table. Moreover, Lokesh has demonstrated his commitment to fostering a technologically aware community by conducting technical workshops on campus. These workshops, aimed at raising awareness about cybersecurity and modern technologies, highlight his dedication to continuous learning and professional growth. Lokesh's proactive approach in sharing his knowledge and expertise underscores his commitment to creating a collaborative and informed environment. Lokesh Balaji's enthusiasm for Al and Data Science, combined with his collaborative nature and innovative mindset, make him an invaluable asset to any organization. I have no doubt that he will excel and make significant contributions wherever he goes." />
          <HonorCard name="Venkat Ramana" issued="Associate Network Engineer | Cisco certified Network associate CCNA | Cyber security Enthusiasts( Creating smiles through great people and Technology)" desc="lokesh is one of the most enthusiastic and well stuffed student during my school days and moreover he was well knowledged about most of the cyber technologies during his school days itself. consistent and knowledgeble person." />
          <HonorCard name="Madhumitha R" issued="Data Analyst | ML Engineer | Problem Solver" desc="Lokesh is curious, collaborative and an enthusiastic individual who is passionate about harnessing Artificial Intelligence and Data Science to drive meaningful impact. He is interested in coding and can excel in any organization, bringing innovative ideas and a strong work ethic. Lokesh has done several projects out of his own interest. He has conducted technical workshops on campus to create awareness regarding cybersecurity and modern technologies. His proactive approach in sharing knowledge highlights his dedication to fostering a technologically aware community and his commitment to continuous learning and professional growth. Lokesh was my classmate, and his proactive approach in sharing knowledge highlights his dedication to fostering a technologically aware community and his commitment to continuous learning and professional growth. His enthusiasm for Al and Data Science, combined with his collaborative nature and innovative mindset, make him an invaluable asset to any organization." />
          <HonorCard name="Joel Joseph" issued="Al & Data Science Engineer | Python & ML Enthusiast | Innovator & Problem Solver" desc="To Whom It May Concern, I am pleased to write this letter of recommendation for Lokesh. I had the privilege of studying alongside Lokesh during our time at Rajalakshmi Institute of Technology, and we collaborated on numerous projects, including hosting websites and various programming tasks. Lokesh consistently demonstrated exceptional technical skills, a strong work ethic, and a remarkable ability to solve complex problems efficiently. Their expertise in web hosting and programming was instrumental in the success of our projects. Whether it was debugging code, optimizing performance, or implementing new features, Lokesh approached each challenge with enthusiasm and professionalism. One of the standout qualities of Lokesh is their willingness to help others. On countless occasions, they took the time to explain difficult concepts, provide guidance, and ensure that everyone on the team understood the tasks at hand. Their patience and clear communication skills made them an invaluable asset to our group.Beyond their technical abilities, Lokesh is a collaborative team player who brings positive energy to any environment. Their contributions went beyond just technical input; they were always ready to support and encourage peers, fostering a collaborative and productive atmosphere. I have no doubt that Lokesh will excel in any professional setting. Their dedication, technical prowess, and supportive nature make them a tremendous asset to any team. I wholeheartedly recommend Lokesh for any opportunity they pursue." />
          <HonorCard name="Jaya Kumar" issued="Final Year Student| Core Java | Golang | Cloud Computing | LTTS Techgium®️ 7th Edition Semi-Finalist | DevOps Enthusiast" desc="Lokesh balaji is highly proficient in DevOps Principles and highly enthusiastic person, I have ever seen. His expertise in DevOps tools and cybersecurity is a great inspiration to me and I had the pleasure to work with lokesh balaji during our college days. I wish Lokesh Balaji would excel and make contributions seamlessly." />
          <HonorCard name="Anantha Krishnan D" issued="B.tech CSE & Medical Engineering | Software Developer" desc="I've had the pleasure of working with Lokesh Balaji at Beez Labs and can confidently say he is an exceptional professional. His technical expertise, strategic thinking, and problem-solving skills have significantly contributed to our team's success. Lokesh is a collaborative team player with excellent communication skills and a proactive approach that drives innovation and efficiency. He is a valuable asset to any organization and I highly recommend him!" />
          <HonorCard name="Suryaa Narayanan K" issued="Senior Student - AI & DS @RIT, Chennai👨🏻‍🎓| Python, C, Javascript, MYSQL 🖥️ | Data Analysis📊" desc="I know Lokesh for the past four years during our time together at college, where we pursued our studies in Artificial Intelligence & Data Science. Throughout our academic journey, Lokesh consistently demonstrated a strong passion and aptitude for technology, particularly in the realms of Ethical Hacking and DevOps. Lokesh's dedication to expanding his knowledge in these fields was evident not only in the classroom but also outside of it. We often attended various workshops and conferences together as a team, where Lokesh actively engaged in discussions, absorbed new concepts, and showcased his enthusiasm for learning. His eagerness to explore emerging trends and technologies in Ethical Hacking and DevOps always stood out, reflecting his commitment to personal and professional growth.I have no doubt that Lokesh's passion, dedication, and proficiency in Ethical Hacking and DevOps will make him a valuable addition to any organization. I wholeheartedly recommend him for opportunities in these areas, confident that he will continue to excel and make significant contributions wherever he goes." />
          <HonorCard name="Ashok M" issued="Research Scholar-Pedagogical Innovations in Engineering Education, e-learning, Computational Intelligence|JNTUCOEA'11|MKUAKCE'03" desc="Lokesh's proficiency in delivering software in the fastest possible way is commendable. He has consistently shown a keen interest in containerization, ensuring the isolation and reusability of applications. His ability to implement DevOps automation strategies has significantly contributed to the efficiency and productivity of our projects. One of Lokesh's standout strengths lies in his expertise in securing REST APIs. His in-depth understanding of cybersecurity principles, combined with his practical experience, has resulted in the successful implementation of robust security measures for our projects. Lokesh's dedication to his work, coupled with his technical acumen, makes him an invaluable asset to any team. His collaborative nature and effective communication skills further enhance his ability to work seamlessly with cross-functional teams." />
          <HonorCard name="Santhosh Kumar Ethiraj" issued="| Founder - Spinacle® | Student at RIT- Chennai | Entrepreneur | Innovator | Analyst | Explorer |" desc="I am delighted to provide a recommendation for Lokesh Balaji, with whom I had the pleasure of studying during our college years. Lokesh's exceptional skills in network coding, hacking, and cloud computing set him apart as a standout professional. His dedication to mastering new technologies, evident in his involvement in numerous projects and attendance at various networking and cloud computing events, speaks volumes about his commitment to excellence. Lokesh's contributions to our projects, especially his research on Pacs Systems, were invaluable. With his innate curiosity, technical proficiency, and collaborative nature, Lokesh is well-equipped to excel in any endeavor he undertakes. I highly recommend Lokesh for his outstanding abilities and unwavering dedication." />
          <HonorCard name="Dhalavai N" issued="RIT College Ambassador @ Rajalakshmi Institute of Technology | API, Flask, HTML Expert,Software Developer , Content Writter ,Ai enthusiasts" desc="Lokesh Balaji is very dedicated and hardworking person with outstanding skills in devops" />
          <HonorCard name="Bakang Khunou" issued="Cloud System Engineer III at QSupport Cloud, South Africa" desc="I highly recommend Lokesh for their exceptional approach to learning and demonstrating their skills in cybersecurity. They are always up-to-date with the latest developments in the field and consistently seek out new ways to enhance their knowledge and expertise. Their commitment to excellence is truly impressive and they would make a valuable asset to any organization seeking a skilled cybersecurity professional." />
          <HonorCard name="Sai Prasaad G" issued="Founder & CEO of Melospiza" desc="Lokesh is an enthusiastic Cyber Security Analyst who consistently stays updated on the latest technologies related to cyber security. His deep understanding of the industry, combined with his analytical and problem-solving skills, make him an invaluable asset to any team. He is a great team player who is always willing to help and share his knowledge with others, making him a pleasure to work with." />
          <HonorCard name="Abishek V P" issued="Full Stack Developer at Rook" desc="Lokesh is Good at Learning and Implementating things. we worked together little. Even in the little thing, We were doing many things. A Perfect team mate to work together and grow together." />
          <HonorCard name="Dhinakaran DGS" issued="Trainee Engineer @ Avasoft & Zeb" desc="Yes Highly Skilled and the enthu on Lokesh for all his Tech & CyberField is at its peak even if you call him at 12, he will be available to pick up and help you out at anything that you want that's a great value you can find on him. Kudos Lokesh." />
        </Slider>
      </div>
      <img src={hr} className="w-full mt-8 md:h-2" alt="hr" />
    </div>
  );
}
